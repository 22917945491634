import { AiFillCar } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { BlogLang } from '../common/blogLang'

export const BlogHeader = () => {

    return (
        <div className="col-12 col-xl-10 mx-auto mt-2">
            <div className="row d-flex align-items-center">
                <div className="col d-flex align-items-center">
                    <Link to="/"><img style={{ width: 60 }} src="/img/logo2.png"></img></Link>
                </div>
                <div className="col-9 col-xl-10">
                    <div className='row d-flex justify-content-end'>
                        <div className='col-12 col-md-5 col-xl-3 d-flex justify-content-end'>
                            <BlogLang />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}