export const WhyUs = () => {
    return (
        <div id="whyUs" className="col-12 col-xl-10 mx-auto mt-5 mb-2">
            <div className="row">

                <div className="col-12 col-md-6 d-flex align-items-center">
                    <div className="col">
                        <div className="row">
                            <h1 className="bold my-4">Why use the aDrive app?</h1>

                            <img className="mb-4 d-md-none" src="/img/phone.png" style={{ width: "35%" }}></img>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-1">
                                        <div className="bullet text-white p-2 me-1 d-flex align-items-center justify-content-center">
                                            1</div>
                                    </div>

                                    <div className="col">
                                        <p className="bold">Live recording</p>
                                    </div>

                                    <div className="col-11 offset-1">
                                        <p>
                                            aDrive offers live video and audio recording features for our
                                            drivers and riders to feel safer driving around the city.
                                        </p>
                                    </div>
                                </div>

                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-1">
                                        <div className="bullet text-white p-2 me-1 d-flex align-items-center justify-content-center">
                                            2</div>
                                    </div>

                                    <div className="col">
                                        <p className="bold">ID Verification</p>
                                    </div>

                                    <div className="col-11 offset-1">
                                        <p> Riders and Drivers are required to provide ID details for
                                            verification to ensure best and safe rides.</p>
                                    </div>

                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-1">
                                        <div className="bullet text-white p-2 me-1 d-flex align-items-center justify-content-center">
                                            3</div>
                                    </div>

                                    <div className="col">
                                        <p className="bold">Rider & Driver extra earnings</p>
                                    </div>

                                    <div className="col-11 offset-1">
                                        <p> Refer a friend and receive
                                            an incentive when they request a ride.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-6 d-none d-md-block">
                    <img className="mx-auto" src="/img/phone.png" style={{ width: 230 }}></img>
                </div>
            </div >
        </div >
    )
}