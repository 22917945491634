import { CommonFooter } from "../componets/common/commonFooter"
import { HeaderUpdates } from "../componets/updates/headerUpdates"
import { useParams } from "react-router-dom"
import { Update1 } from "../content/updates/update1"

export const Updates = () => {
    const { id } = useParams()
    document.title = "Updates | aDrive"

    return (
        <div className="container-fluid">
            <div className="row">
                <HeaderUpdates />
                {id == 1 ?
                    <Update1 id={id} /> :
                    <h1 className="bold my-5">Page not found</h1>
                }
                <CommonFooter />
            </div>
        </div >
    )
}