import { Link } from "react-router-dom"

export const JobsTable = ({ jobs }) => {

    console.log(jobs)

    return (
        <>
            <div className="row mt-2">
                <div className="col">
                    <p className="bold">Positions</p>
                </div>
                <div className="col">
                    <p className="bold">Department</p>
                </div>
                <div className="col">
                    <p className="bold">Location</p>
                </div>
            </div>

            {
                jobs.map((job, index) =>
                    <div key={index} className="row my-2" >
                        <div className="col">
                            <Link onClick={() => window.scroll(0, 0)} to={'../career/' + (job.id)}><p className=' text-primary'>{job.name}</p></Link>
                        </div >
                        <div className="col">
                            <p>{job.department}</p>
                        </div>
                        <div className="col">
                            <p>{job.location}</p>
                        </div>
                    </div>
                )

            }
        </>
    )
}