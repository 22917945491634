import { useState } from "react";

export const ContactFrom = () => {
    const [error, setError] = useState("")

    const send = () => {
        const email = "info@adriveapp.com"
        const category = document.getElementById('category');
        const subject = document.getElementById('subject');
        const message = document.getElementById('message');
        const body = "Dear Sir/Madam <br/><br/> I would like to inquire about " + category.value + ".<br/> <br/>" + message.value

        if (category.value == "") {
            category.style.border = "solid red 1px"
            setError(prev => "Select category")
            return
        }

        if (category.value != "") {
            category.style.border = "solid #dddd 1px"
        }

        if (subject.value == "") {
            subject.style.border = "solid red 1px"
            setError(prev => "Please enter subject")
            return
        }

        if (subject.value != "") {
            subject.style.border = "solid #dddd 1px"
        }

        if (message.value == "") {
            message.style.border = "solid red 1px"
            setError(prev => "Please enter your message")
            return
        }

        if (message.value != "") {
            message.style.border = "solid #dddd 1px"
        }

        setError(prev => "")

        window.location.href = "mailto:" + email + "?subject=" + subject.value + "&body=" + body
    }

    return (
        <div className="col-12 col-xl-10 mx-auto my-5">
            <div className="row">
                <div className="col-sm-12 col-md">
                    <h1 className="bold mb-2">Contact us</h1>

                    <p>Customer support : <a className='text-primary' href="mailto:southafrica@adriveapp.com">southafrica@adriveapp.com</a></p>

                    <p>Complaints : <a className='text-primary' href="mailto:complaint@adriveapp.com">complaint@adriveapp.com</a></p>

                    <p>Tel: <a className='text-primary' href="tel:012 111 8536">012 111 8536</a></p>

                    <p>WhatsApp: <a className='text-primary' href="tel:064 942 9969">064 942 9969</a></p>

                    <p>Headquarters: South Africa</p>

                    <h1 className="bold mb-2">Contact Form</h1>
                    <p className="mt-2">Category</p>
                    <select id="category" className="form-select">
                        <option value=''> -- select category --</option>
                        <option value='Category 1'> Category 1</option>
                        <option value='Category 2'> Category 2</option>
                    </select>

                    <p className="mt-2">Subject</p>
                    <input id="subject" placeholder="subject" className="form-control"></input>

                    <p className="mt-2">Message</p>
                    <textarea id="message" style={{ height: 100 }} placeholder="your message here ..." className="form-control"></textarea>

                    <button onClick={send} className="btn primaryBtn mt-3">Send</button>

                    <p className="mt-2 text-danger">{error}</p>
                </div>
            </div>

        </div>
    )
}