export const AvailableAirports = () => {
    return (
        <div className="col-12 col-xl-10 mx-auto my-5">
            <div className="row">
                <div className="col-sm-12 col-md">
                    <h1 className="bold">South Africa</h1>
                </div>

                <div className="col-sm-12 col-md mt-3 mt-sm-3 mt-md-0">
                    <p >Cape Town International Airport</p>
                    <p >King Shaka International Airport</p>
                    <p >Lanseria International Airport</p>
                    <p >O.R. Tambo international Airport</p>
                    <p >Port Elizabeth International Airport</p>
                </div>
            </div>

        </div>
    )
}