import { ShareBlog } from "./share"

export const Blog2 = ({ id }) => {
    document.title = 'Introducing video and audio trip recording features.'
    return (
        <div className="col-12">
            <h1 className="bold mt-4">Avoid creating fake user profiles: aDrive mitigates creating of fake user
                accounts on its e-hailing app</h1>
            <p>23 Sep 2023 | South Africa</p>

            <img className='mb-4' style={{ width: "100%" }} src='/img/blog2.jpg'></img>

            <p className='mt-3'>Your safety as a riders and driver comes first. To enhance safety and avoid creation of fake
                profile and accounts, we have introduced an Identity Verification Process (IVP) for both
                riders and drivers to provide proof of identity during registration/sign-up process.</p>

            <p>We are responding to the rising demand of secure safety toolkits, this feature will ensure
                users do not create fake profile/account with intention to conduct in appropriate behaviours
                such as hijacking, human trafficking, and other violent acts.</p>

            <p className="bold">Driver and Rider Safety Concerns</p>

            <p>aDrive IVP was developed following these concerns, <span style={{ fontStyle: "italic" }}>”a rider attempted to hijack my vehicle
                when I arrived at the requested pick-up spot, the rider was not traced as it was a fake
                profile”, said, e-hailing Driver. A rider raised a concern on social media: “Dear ladies, please
                be careful, I was robbed on Sunday, the driver even stabbed me in the shoulder and took my
                phone”.</span> aDrive is committed to use all its safety toolkits to solve these issues.</p>

            <p className="bold">ID Verification for Riders and Drivers</p>

            <p>The IVP will ensure that aDrive monitors and keeps track of real identities of all Rider and
                Drivers captured in ID documents or VISAs. Following this safety regulation and ID
                authentication process, we believe all our users will be at ease to explore the city during the
                day and night.</p>

            <ShareBlog url={"https://adriveapp.com/blog/" + id} />
        </div>
    )
}