import { CommonFooter } from "../componets/common/commonFooter"
import { InvestorHeader } from "../componets/investors/headerInvestors"
import { useParams } from "react-router-dom"
import { Investor1 } from "../content/investors/investor1"

export const Investors = () => {
    document.title = "Investors | aDrive"
    const { id } = useParams()
    return (
        <div className="container-fluid">
            <div className="row">
                <InvestorHeader />
                {id == 1 ?
                    <Investor1 id={id} /> :
                    <h1 className="bold my-5">Page not found</h1>
                }
                <CommonFooter />
            </div>
        </div >
    )
}