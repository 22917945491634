import { Link } from "react-router-dom"

export const Companion = () => {
    return (
        <div id="companion" className="col-12 col-xl-10 mx-auto mt-5 mb-2">
            <h1 className="bold">Travel companion</h1>

            <div className="row mt-2">
                <div className="col-12 col-md-6 mb-2">
                    <img className="mx-auto my-2 companion" src="img/city.jpg" style={{ width: "100%" }}></img>
                    <p><b>Explore cities</b></p>
                    <p>aDrive is available in various cities internationally, request a ride
                        anywhere, anytime.</p>
                    <Link onClick={() => window.scroll(0, 0)} className='text-dark my-2 borderCust pb-1' to='/cities'>Search city</Link>
                </div>
                <div className="col-12 col-md-6 mb-2">
                    <img className="mx-auto my-2 companion" src="img/airpot.jpg" style={{ width: "100%" }}></img>
                    <p><b>Airport rides</b></p>
                    <p> aDrive is here to be your safe travel companion to and from various
                        airports for your convenience.</p>
                    <Link onClick={() => window.scroll(0, 0)} className='text-dark my-2 borderCust pb-1' to='/airports'>Search airport</Link>
                </div>
            </div>
        </div>
    )
}