import { Link } from "react-router-dom"
import { ShareBlog } from "./share"

export const Blog3 = ({ id }) => {
    document.title = 'aDrive introduces freight hailing for deliveries'
    return (
        <div className="col-12">
            <h1 className="bold mt-4">aDrive introduces freight hailing for deliveries</h1>
            <p>30 Nov 2023 | South Africa</p>

            <img className='mb-4' style={{ width: "100%" }} src='/img/blog3.jpg'></img>

            <p className='mt-3'>aDrive takes pride when it comes to developing innovative solutions within the mobility
                industry. We are introducing freight deliveries with different vehicle sizes ranging from small
                to heavy trucks.</p>

            <p>Gone are those days when you had to call a truck driver and negotiate prices for a package
                delivery. aDrive freight delivery allows users to request:</p>
            <p>
                <li>Small truck/bakkie - handle appliances like stove, fridge (400kg).</li>
                <li>Mid-sized truck transfers double-bed, large couch (1.5ton).</li>
                <li>Large truck handles furniture, large boxes (5ton).</li>
            </p>

            <p>aDrive freight deliveries are handled with high security measures through live vehicle
                tracking, audio and video trip recording and Driver Identity Verification Process (DIVP) to
                ensure safe delivery of your package.</p>

            <p className="bold">How aDrive freight delivery works:</p>

            <p>
                <li>For first-time users – select your pick-up and drop-off point.</li>
                <li>Browse to vehicle options – select “truck” and choose the type of truck you want to
                    request.</li>
                <li>Confirm your request details and record the trip through audio and video recorded
                    by clicking “start recording”.</li>
                <li>Final step - After arriving to your destination, click on “End Recording”, and the app
                    will confirm package delivery.</li>
            </p>

            <p>This new feature will be first launched and tested in South Africa, and you must have the
                latest version of the app to use it. We are excited to hear about your experience using
                aDrive freight deliveries.</p>

            <p>To learn more about aDrive freight delivery, please download our app on this
                <Link className='text-primary' to=''> link</Link>  and start requesting in seconds.</p>

            <ShareBlog url={"https://adriveapp.com/blog/" + id} />
        </div>
    )
}