import { FAQ1, FAQ2, FAQ3, FAQ4 } from "../../models/FormFAQ"
import { FaChevronRight } from "react-icons/fa6";

export const FAQ = () => {
    return (
        <div id="faq" className="col-md-12 col-xl-10 mx-auto mt-5 mb-2">
            <h1 className="bold">FAQ</h1>

            <div onClick={FAQ2} className="mx-1 mt-2 row rounded shadow-sm faq">
                <div className="col-10 my-2 d-flex align-items-center">
                    <p className="m-2 bold">Is aDrive available in my city?</p>
                </div>

                <div className="col-2 my-2 d-flex justify-content-end align-items-center">
                    <p className="m-2 carClass"><FaChevronRight size={12} color={"rgba(0,0,0,.8)"} /></p>
                </div>

                <div id="faq2" className="col-12 d-none">
                    <p className="m-2"> <li>You can find all the available cities in `Search
                        city`</li></p>
                </div>
            </div>

            <div onClick={FAQ3} className="mx-1 mt-2  row rounded shadow-sm faq">
                <div className="col-10 my-2 d-flex align-items-center">
                    <p className="m-2 bold">How do I request a ride?</p>
                </div>

                <div className="col-2 my-2 d-flex justify-content-end align-items-center">
                    <p className="m-2 carClass"><FaChevronRight size={12} color={"rgba(0,0,0,.8)"} /></p>
                </div>

                <div id="faq3" className="col-12 d-none">
                    <p className="m-2"> <li>Open the aDrive app, select pickup location and vehicle type. Then confirm your trip details
                        by tapping ‘Accept’ option</li></p>
                </div>
            </div>

            <div onClick={FAQ4} className="mx-1 mt-2  row rounded shadow-sm faq">
                <div className="col-10 my-2 d-flex align-items-center">
                    <p className="m-2 bold">How safe is aDrive?</p>
                </div>

                <div className="col-2 my-2 d-flex justify-content-end align-items-center">
                    <p className="m-2 carClass"><FaChevronRight size={12} color={"rgba(0,0,0,.8)"} /></p>
                </div>

                <div id="faq4" className="col-12 d-none">
                    <p className="m-2"> <li>aDrive allows riders and drivers to record live trips through
                        video and audio recording features.</li></p>
                </div>
            </div>

            <div onClick={FAQ1} className="mx-1 mt-2 row rounded shadow-sm faq">
                <div className="col-10 my-2 d-flex align-items-center">
                    <p className="m-2 bold">Why aDrive requires ID Verification?</p>
                </div>

                <div id="toogle" className="col-2 my-2 d-flex justify-content-end align-items-center">
                    <p className="m-2 carClass"><FaChevronRight size={12} color={"rgba(0,0,0,.8)"} /></p>
                </div>

                <div id="faq1" className="col-12 d-none">
                    <p className="m-2"> <li>Riders and Drivers are required to provide
                        proof of identification to avoid fake profiles and ensure safe rides for all users.</li></p>
                </div>
            </div>

        </div >
    )
}