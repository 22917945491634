let url = "";

export const DriverApp = () => {
    url = "https://driverapp.com"
}

export const RiderApp = () => {
    url = "https://riderapp.com"
}

export const DownloadApp = () => {
    window.location.href = url
}
