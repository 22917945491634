import { BlogHeader } from "../componets/blogs/blogHeader"
import { CommonFooter } from "../componets/common/commonFooter"
import { useParams } from "react-router-dom"
import { redirect } from "react-router-dom"

import { Blog3 } from "../content/blogs/blog3"
import { Blog1 } from "../content/blogs/blog1"
import { Blog2 } from "../content/blogs/blog2"
import { Blog4 } from "../content/blogs/blog4"

export const Blog = () => {
    const { id } = useParams()

    document.title = "Blog"

    return (
        <div className="container-fluid">
            <div className="row">
                <BlogHeader />
                <div className="col-12 col-xl-10 mx-auto">
                    {id == 3 ?
                        <Blog3 id={id} /> :
                        id == 1 ?
                            <Blog1 id={id} /> :
                            id == 2 ?
                                <Blog2 id={id} />
                                : id == 4 ?
                                    <Blog4 id={id} />
                                    :
                                    <h1 className="bold my-5">Page not found</h1>
                    }
                </div>
                <CommonFooter />
            </div>
        </div >
    )
}