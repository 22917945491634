import { useCountries } from 'react-countries'

export const BlogLang = () => {
    const setCountry = () => {
        const language = document.getElementById('countries').value
        localStorage.setItem("lang", language)
    }

    const { countries } = useCountries()

    return (
        <select id="countries" onChange={setCountry} style={{ background: "none", border: 'none', textAlign: "right" }} className="form-control" name="contries">
            {localStorage.getItem('lang') &&
                <option className='text-dark' value={localStorage.getItem('lang')}>{localStorage.getItem('lang')}</option>
            }
            {
                countries.map(({ name, flag }, key) =>
                    <option key={key} className='text-dark' value={flag + '  ' + name + " (EN)"}>{flag}  {name}  (EN)</option>
                )
            }
        </select>
    )
}