import { JobsTable } from "./jobs"
import { jobs } from "./career"
import { useState } from "react"

export const CareerBody = () => {

    const [siteJobs, setJobs] = useState(jobs)
    const [positions, setPositions] = useState(jobs.length)

    const filter = () => {
        const department = document.getElementById('department').value
        const position = document.getElementById('position').value
        const location = document.getElementById('location').value
        if (department != "") {
            filtering(department, position, location)
        }
        if (position != "") {
            filtering(department, position, location)
        }
        if (location != "") {
            filtering(department, position, location)
        }
    }

    const filtering = (department, position, location) => {
        let newJobs = new Array()
        let count = 0;

        for (let i = 0; i < jobs.length; i++) {
            if (jobs[i].department.replaceAll(" ", "").toUpperCase() == department.replaceAll(" ", "").toUpperCase()) {
                newJobs[count] = jobs[i]
                count += 1
                continue;
            }
            if (jobs[i].name.replaceAll(" ", "") == position.replaceAll(" ", "")) {
                newJobs[count] = jobs[i]
                count += 1
                continue;
            }
            if (jobs[i].location.replaceAll(" ", "") == location.replaceAll(" ", "")) {
                newJobs[count] = jobs[i]
                count += 1
                continue;
            }
        }

        if (newJobs.length > 0) {
            /*  setJobs(prev => newJobs)
             setPositions(prev => newJobs) */
            console.log(newJobs)
        }

        setPositions(prev => newJobs)
    }

    return (
        <div className="col-12 col-xl-10 mx-auto mt-5">
            <div className="row">
                {/* <div className="col col-md-2">
                    <select className='rounded' style={{ width: "100%" }} id="position">
                        <option value="">--Position--</option>
                        <option values='Customer Support Representative'>Customer Support Representative</option>
                        <option values='Payroll Specialist'>Payroll Specialist</option>
                        <option values='Mobility marketing Specialist'>Mobility marketing Specialist</option>
                        <option values='Operations Manager'>Operations Manager</option>
                        <option values='Talent Acquisition Specialist'>Talent Acquisition Specialist</option>
                        <option values='Senior Full Stack Engineer'>Senior Full Stack Engineer</option>
                        <option values='Senior Legal Counsel'>Senior Legal Counsel</option>
                    </select>
                </div>
                <div className="col col-md-2">
                    <select className='rounded' style={{ width: "100%" }} id="department">
                        <option value="">--Department--</option>
                        <option value='Sales & Applications'>Sales & Applications</option>
                        <option value='Finance & Accounting'>Finance & Accounting</option>
                        <option value='Operations'>Operations</option>
                        <option value='Human Resources'>Human Resources</option>
                        <option value='Information and Communication Technologies (ICT)'>Information and Communication Technologies (ICT)</option>
                        <option value='Legal, Regulation and Compliance'>Legal, Regulation and Compliance</option>
                    </select>
                </div>
                <div className="col col-md-2">
                    <select className='rounded' style={{ width: "100%" }} id="location">
                        <option value="">--Location--</option>
                        <option value='Johannesburg, South Africa'>Johannesburg, South Africa</option>
                    </select>
                </div>
                <div className="col col-md-2">
                    <button onClick={filter} className="btn primaryBtn">Search</button>
                </div> */}
            </div>

            <div className="row">
                <p className="my-3">{positions} Opening Positions</p>
                <JobsTable jobs={siteJobs} />
            </div>

            <button className="btn primaryBtn my-4">Explore more opportunities</button>
        </div>
    )
}