import { ShareBlog } from "./share"

export const Blog4 = ({ id }) => {
    document.title = 'Beta Testing in Four Cities with aDrive'
    return (
        <div className="col-12">
            <h1 className="bold mt-4">Beta Testing in Four Cities with aDrive</h1>
            <p>11 Sep 2023 | South Africa</p>

            <img className='mb-4' style={{ width: "100%" }} src='/img/blog4.jpg'></img>

            <p className='mt-3'>aDrive is set to conduct a beta testing of its first ever e-hailing mobile application developed.
                In response to the rising demand of a safe and affordable solution, aDrive developed an
                innovation that solves problems encountered in the marketplace.</p>

            <p className="bold">Beta testing in South African Cities</p>

            <p className='mt-3'>The beta testing will be conducted in South Africa within the following four regions: Pretoria,
                Johannesburg, Cape Town, and Durban from November 2023. We will require a maximum of
                50 drivers and 50 riders in each city, overall, the test will have a total number of 400 beta
                testers in all four cities.</p>

            <p className='bold'>Rider and Driver discounts and promotions</p>

            <p className='mt-3'>Each rider will receive a discount of 50% for a maximum of 5 rides anywhere, anytime. Our
                drivers will receive a bonus fare of R30 for every trip including the trip fares.</p>

            <img style={{ width: "20%" }} className="mb-4" src="../splash.png"></img>

            <p className='bold'>Pilot program and App Launch plan in South Africa</p>

            <p className='mt-3'>aDrive Technologies announced today, Bonginkosi Mabaso, Founder and CEO, will confirm
                the dates for the pilot program, he said <span style={{ fontStyle: "italic" }}>‘’aDrive developed a
                    solution that strengthens
                    rideconvenience, affordability and safety through live video and audio recording features, and
                    Identity verification process for both riders and drivers. We have responded to the rising
                    demand, as a result, we urge drivers and riders to test our platform to ensure we fix any
                    technical issues, disruptions, errors or lack of functionality before we make the aDrive
                    app live’’.</span></p>

            <p className='mt-3'>aDrive Technologies will soon roll-out the pilot program before launching live for commercial
                use. We believe this test will provide us with results to make this app your preferred solution
                to explore cities safely in style.</p>

            <ShareBlog url={"https://adriveapp.com/blog/" + id} />
        </div>
    )
}