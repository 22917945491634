export const Class = (setCar) => {
    document.getElementById('class').style.borderBottom = "solid 4px #0f4d92"
    document.getElementById('premium').style.borderBottom = "unset"
    document.getElementById('freight').style.borderBottom = "unset"
    document.getElementById('hotel').style.borderBottom = "unset"
    setCar(prev => 'class')
}

export const Premium = (setCar) => {
    document.getElementById('class').style.borderBottom = "unset"
    document.getElementById('premium').style.borderBottom = "solid 4px #0f4d92"
    document.getElementById('freight').style.borderBottom = "unset"
    document.getElementById('hotel').style.borderBottom = "unset"
    setCar(prev => 'premium')
}

export const Freight = (setCar) => {
    document.getElementById('class').style.borderBottom = "unset"
    document.getElementById('premium').style.borderBottom = "unset"
    document.getElementById('freight').style.borderBottom = "solid 4px #0f4d92"
    document.getElementById('hotel').style.borderBottom = "unset"
    setCar(prev => 'freight')
}

export const Hotel = (setCar) => {
    document.getElementById('class').style.borderBottom = "unset"
    document.getElementById('premium').style.borderBottom = "unset"
    document.getElementById('hotel').style.borderBottom = "solid 4px #0f4d92"
    document.getElementById('freight').style.borderBottom = "unset"
    setCar(prev => 'hotel')
}