import { useRef, useEffect } from "react"
import { Link } from "react-router-dom"

export const DriverRequirements = ({ section }) => {
    const myRef = useRef(null)

    useEffect(() => {
        if (section == "requirements") {
            myRef.current.scrollIntoView()
        }
    }, [])

    return (
        <div ref={myRef} id="driver" className="col-12 col-xl-10 mt-4 mx-auto mb-2">
            <div className="row mt-4">
                <h1 className="bold  d-block d-lg-none my-4">Driver requirements</h1>

                <div className="col-sm-12 col-12 col-lg-6 d-flex justify-content-center align-items-center">
                    <img className="mx-auto" src="/img/req.jpg" style={{ width: "100%" }}></img>
                </div>

                <div className="col-12 col-lg-6 d-flex align-items-center">
                    <div className="col mt-lg-0 mt-5">
                        <div className="row">
                            <h1 className="bold my-4 d-lg-block d-none">Driver requirements</h1>
                            <p>
                                <li> A valid South African Professional driving permit (PrDP)</li>
                                <li>Driver must be a minimum of 21 years of age</li>
                                <li>Provide a driver profile photo</li>
                                <li>Valid driver's License</li>
                                <li> Criminal Record check Report</li>
                                <li>Dekra Inspection report</li>
                                <li>Profile picture</li>
                                <li>Motor Vehicle inspection Licensing Disk with operator card</li>
                            </p>
                            <p className='mx-3 my-4'>By Signing up, you agree to our <span className='text-primary'><Link  className='text-primary' onClick={()=>window.scroll(0,0,)} to='/ad/legal'>Terms and Conditions</Link></span> and <span className='text-primary'><Link  className='text-primary' onClick={()=>window.scroll(0,0,)} to='/ad/legal'>Privacy policy</Link></span></p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}