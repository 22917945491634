import { Premium, Class, Freight, Hotel } from "../../models/FormCarClass"
import { ClassCars, FreightCars, PremiumCars, HotelCars } from "../../models/car"
import { useState, useEffect } from "react"
import { useRef } from "react"

export const ShowDown = ({ section }) => {

    const [car, setCar] = useState("class")
    const myRef = useRef(null)
    const hotel = useRef(null)
    const freight = useRef(null)

    useEffect(() => {
        if (section == "rider") {
            myRef.current.scrollIntoView()
        }
        if (section == "hotel") {
            myRef.current.scrollIntoView()
            hotel.current.click()
        }
        if (section == "freight") {
            myRef.current.scrollIntoView()
            freight.current.click()
        }
    }, [])

    return (
        <div ref={myRef} id="showDown" className="col-12 col-xl-10 mx-auto mt-5 mb-2">
            <h1 className="bold">Explore the city with class</h1>

            <div className="row mt-4">
                <div className="col col-xl-1">
                    <span onClick={() => Class(setCar)} id="class" className='carClass' style={{ borderBottom: "solid 4px #0f4d92" }}>Class</span>
                </div>
                <div className="col col-xl-1">
                    <span onClick={() => Premium(setCar)} id="premium" className='carClass'>Premium</span>
                </div>
                <div className="col col-xl-1">
                    <span ref={freight} onClick={() => Freight(setCar)} id="freight" className='carClass'>Freight</span>
                </div>
                <div className="col col-xl-1">
                    <span ref={hotel} onClick={() => Hotel(setCar)} id="hotel" className='carClass'>Hotel</span>
                </div>
            </div>

            <div id="carsShowdown" className="row mt-5">
                {
                    car == "class" &&
                    <ClassCars />
                }
                {
                    car == "premium" &&
                    <PremiumCars />
                }
                {
                    car == "freight" &&
                    <FreightCars />
                }
                {
                    car == "hotel" &&
                    <HotelCars />
                }
            </div>
        </div >
    )
}