import { CommonFooter } from "../componets/common/commonFooter";
import { CareerBody } from "../componets/careers/careerBody";
import { CareerHeader } from "../componets/careers/headerInvestors";
import DocumentMeta from "react-document-meta";

export const Careers = () => {
  document.title = "Careers";

  const meta = {
    title: "Careers | aDrive",
    description: "Unlock your potential at ADrive! Explore diverse career opportunities that fuel innovation and growth. Join us in shaping the future of cloud technology, where your skills meet endless possibilities.",
    canonical: "http://adriveapp.com/ad/careers/",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
        "aDrive,aDrive app,adrive south africa,uber south africa,driver app",
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      <div className="container-fluid">
        <div className="row">
          <CareerHeader />
          <CareerBody />
          <CommonFooter />
        </div>
      </div>
    </DocumentMeta>
  );
};
