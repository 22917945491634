import { FaFacebookSquare, FaInstagram, FaLinkedin, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { FaApple, FaGooglePlay } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import { FooterFreight, FooterHotel } from '../../models/footerProducts';

export const Footer = () => {
    return (
        <div style={{ background: "rgba(0,0,0,.95)" }} className="col-12 text-white mt-5 pb-4">
            <div id="footer" className="col-xl-10 mx-auto mt-5">
                <div className="row">
                    <div className="col-3 col-md-3">
                        <p><b>Company</b></p>
                        <Link onClick={() => window.location.href = "#aboutus"} to="">About us</Link><br />
                        <Link onClick={() => window.scroll(0, 0)} to="/contact">Contact</Link><br />
                        <Link onClick={() => window.location.href = "#showDown"} to="">Showroom</Link><br />
                        <Link onClick={() => window.scroll(0, 0)} to="/cities">Cities</Link><br />
                        <Link onClick={() => window.scroll(0, 0)} to="/airports">Airports</Link><br />
                        <Link onClick={() => window.location.href = "#partners"} to="">Partners</Link><br />
                    </div>
                    <div className="col-3 col-md-3 offset-1 offset-md-0">
                        <p><b>aDrive</b></p>
                        <Link onClick={() => window.scroll(0, 0)} to="/ad/legal">Legal</Link><br />
                        <Link onClick={() => window.scroll(0, 0)} to="/ad/blogs">Blogs</Link><br />
                        <Link onClick={() => window.scroll(0, 0)} to="/ad/careers">Careers</Link><br />
                        <Link onClick={() => window.scroll(0, 0)} to="/ad/updates/1">Updates</Link><br />
                        <Link onClick={() => window.scroll(0, 0)} to="/ad/investors/1">Investors</Link><br />
                    </div>
                    <div className="col-3 col-md-3 offset-1 offset-md-0">
                        <p><b>Products</b></p>
                        <Link onClick={() => window.location.href = "#showDown"} to="">Rider</Link><br />
                        <Link onClick={() => window.location.href = "#driver"} to="">Driver</Link><br />
                        <Link reloadDocument to="/freight">Freight</Link><br />
                        <Link reloadDocument to="/hotel">Hotel</Link><br />
                    </div>

                    <div className='col-md-3 col-sm-12'>
                        <div className='row mt-3 mt-md-0'>
                            <div className='col-12 mt-2 d-flex justify-content-md-end justify-content-sm-start'>
                                <Link to="">
                                    <div style={{ width: 120 }} className='row mx-1 border border-white rounded py-2'>
                                        <div className='col-1'>
                                            <FaGooglePlay className='play' size={20} color={"#fff"} />
                                        </div>
                                        <div className='col ms-1'>
                                            <span className='ms-1'>Android</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-12 mt-2 d-flex justify-content-md-end justify-content-sm-start'>
                                <Link to="">
                                    <div style={{ width: 120 }} className='row mx-1 border border-white rounded py-2 d-flex align-items-center'>
                                        <div className='col-1'>
                                            <FaApple className='social' size={25} color={"#fff"} />
                                        </div>
                                        <div className='col ms-1'>
                                            <span className='ms-1'>Apple</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className='col col-md-1'>
                        <Link to=""><FaFacebookSquare className='social' size={30} color={"#ffff"} /></Link>
                    </div>
                    <div className='col col-md-1'>
                        <Link to=""><FaTwitter className='social' size={30} color={"#ffff"} /></Link>
                    </div>
                    <div className='col col-md-1'>
                        <Link to=""><FaYoutube className='social' size={30} color={"#ffff"} /></Link>
                    </div>
                    <div className='col col-md-1'>
                        <Link to=""><FaLinkedin className='social' size={30} color={"#ffff"} /></Link>
                    </div>
                    <div className='col col-md-1'>
                        <Link to=""><FaInstagram className='social' size={30} color={"#ffff"} /></Link>
                    </div>
                    <div className='col col-md-1'>
                        <Link to=""><FaWhatsapp className='social' size={30} color={"#ffff"} /></Link>
                    </div>
                </div>

                <div className="row mt-5">
                    <p>© aDrive {new Date().getFullYear()}</p>
                </div>
            </div>
        </div >
    )
}