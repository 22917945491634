import { ShareBlog } from "../blogs/share"
import { Link } from "react-router-dom"

export const Update1 = ({ id }) => {
    return (
        <div className="col-12 col-xl-10 mx-auto mt-5">
            <h1 className="bold mb-2">Recent updates</h1>

            <p className="bold">Rider and Driver requirements: registration process</p>

            <p> 06 Oct 2023 | South Africa</p>
            <p> Source: aDrive Technologies</p>

            <img style={{ width: "100%" }} src='/img/recentUpdates.jpg'></img>
            <p className="mt-2">Moving around the city as drivers and riders should not only be a matter of ride affordability
                and convenience, but also promoting safe rides. aDrive has created a ride community that
                connects and verifies all users within the platform to ensure that as a driver/rider, you arrive
                at your destination safe.</p>

            <p>Following the success of aDrive launch in South Africa within the cities we are available,
                drivers and riders are required to submit evidence of all the listed information to create user
                accounts. Please read through below and provide what is required from you to drive/ride
                with aDrive:
            </p>

            <p className="mt-2 bold">Driver requirements</p>

            <p className="mt-2">aDrive reserves the right to require drivers to present evidence and submit for review all the
                necessary documents: licenses, permits and certifications. We are looking for professional
                drivers to explore cities in style. To enjoy aDrive driving experience, a driver is required to
                submit the information listed below:</p>

            <p>
                <li>A valid South African Professional Driving Permit (PrDP)</li>
                <li>Motor Vehicle inspection Licensing Disk with operator card</li>
                <li>Huru Criminal Record check Report</li>
                <li>ID Document</li>
                <li>Inspection report and vehicle insurance</li>
                <li>Profile picture</li>
                <li>You have to be a minimum of 21 years of age</li>
            </p>

            <p className="mt-2 bold">Rider requirements</p>

            <p className="mt-2">aDrive is committed to ensure all riders within the aDrive app are verified to promote safe
                rides when exploring cities. To enjoy aDrive ride experience, a rider is required to submit the
                information listed below:</p>

            <p>
                <li>Name and Surname</li>
                <li>Submit Identification details – ID or Passport number</li>
                <li>Email address and profile picture – encourage safe and quick ride acceptance rate</li>
                <li>Contact number</li>
                <li>Password</li>
            </p>

            <p className="mt-2">To read more about aDrive safety features, please visit this <Link className='text-primary' onClick={()=>window.scroll(0,0)} to='/blog/2'>link.</Link>  To download
                our app, please click on this <Link className="text-primary">link</Link>. Let’s re-define safe, convenient and affordable rides to
                together</p>

            <ShareBlog url={"https://adriveapp.com/ad/updates/" + id} />
        </div>

    )
}