import { DriverApp, RiderApp, DownloadApp } from "../../models/FormDownload"
import { useState } from "react"

const driverTxt = "Join our professional +100 million global team of drivers and earn more with extra commission."
const riderTxt = "Safe and Rewarding rides made easy with aDrive. A-top class ride experience for you!"

export const Download = () => {
    const [text, setText] = useState(driverTxt)
    const [img, setImg] = useState('img/driver.png')

    const driver = () => {
        setText(prev => driverTxt)
        DriverApp()
    }

    const rider = () => {
        setText(prev => riderTxt)
        RiderApp()
    }

    const changed = () => {
        const value = document.getElementById("choice").value
        if (value == "rider") {
            setText(prev => riderTxt)
            setImg(prev => 'img/rider.png')
            RiderApp()
        }

        if (value == "driver") {
            setText(prev => driverTxt)
            setImg(prev => 'img/driver.png')
            DriverApp()
        }
    }

    return (
        <div id="download" className="col-xl-10 mx-auto mt-5 mb-2">
            <center><h1 className="bold">Download App</h1></center>
            <div className="row my-4 d-flex aling-items-center justify-content-center">
                <select className='rounded form-select' onChange={changed} id="choice" style={{ height: "45px", textAlign: "center", width: "90px" }}>
                    <option value='driver'>Driver</option>
                    <option value='rider'>Rider</option>
                </select>
            </div>
            <div className="row">
                <div style={{ background: "#0083AC" }} className="col-11 col-md-10 col-xl-8 mx-auto mt-2 rounded">
                    <div className="row">
                        <div className="col-12 py-md-0 py-5 col-md-8 mt-4 d-flex justify-content-center align-items-center">
                            <div className="col d-flex">
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="bold text-white text-center">{text}</h3>
                                    </div>

                                    <div className="mt-2 col-12 d-flex justify-content-center">
                                        <button onClick={DownloadApp} className="btn primaryBtn">Download</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-md-block col-md-4 d-flex justify-content-end">
                            <img className="mx-auto my-4" src={img} style={{ width: 150 }}></img>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}