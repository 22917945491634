import { ShareBlog } from "../blogs/share"

export const Investor1 = ({ id }) => {
    return (
        <div className="col-12 col-xl-10 mx-auto mt-5">
            <h1 className="bold mb-2">Recent updates and Partnerships</h1>

            <p className="bold">22 August 2022</p>

            <p> Technology Innovation Agency approves funding for aDrive</p>

            {/*  <button className="btn primaryBtn mb-2">Read More</button>
 */}
            <p className="mt-2">An Award and funding have been granted to aDrive for
                Innovation Development by Technology Innovation Agency
                (TIA). aDrive Technologies announced today that Bonginkosi
                Mabaso, Founder and CEO, will lead Beta Testing to take place
                in Johannesburg,Pretoria, Cape Town, and Durban in November 2023.</p>

            <p className="bold">Message from Technology Innovation Agency (TIA), Dr
                Anitha Ramsuran</p>

            <p><i>“It is with pleasure that I confirm approval for funding and support
                for your innovation titled aDrive, through the Grassroots Innovation Programme.
                This programme provides support and funding for the development of
                new products/processes innovations up to a functional prototype and market
                testing of an existing product/process innovation implementation of a proof
                of concept or functional prototype”</i> said Dr Anitha Ramsuran, Manager:
                Innovation for Inclusive Development.
            </p>

            <p>aDrive is committed to re-define safe, convenient and affordable rides –
                reshaping rides in cities with investor partnerships. Bonginkosi Mabaso, announced
                today ‘’aDrive raised funding from TIA, R260 000 for technology
                development and we are looking to secure more funding, support
                and partnerships in South Africa and internationally’’.</p>

            <p>For investor relations, get in touch with us: <a className='text-primary' href="mailto:investor@adriveapp.com">investor@adriveapp.com</a></p>

            <p>Source: aDrive Technologies.</p>

            <ShareBlog url={"https://adriveapp.com/ad/investors/" + id} />
        </div>

    )
}