import { FacebookIcon, WhatsappIcon, TwitterIcon, LinkedinIcon, FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton } from "react-share"
export const ShareBlog = ({ url }) => {
    return (
        <p>Share post
            <FacebookShareButton className='ms-2' url={ShareBlog}>
                <FacebookIcon round size={25} />
            </FacebookShareButton>
            <TwitterShareButton url={url}>
                <TwitterIcon round size={25} />
            </TwitterShareButton>
            <LinkedinShareButton url={url}>
                <LinkedinIcon round size={25} />
            </LinkedinShareButton>
            <WhatsappShareButton url={url}>
                <WhatsappIcon round size={25} />
            </WhatsappShareButton>
        </p>
    )
}
