import { CommonFooter } from "../componets/common/commonFooter";
import { CitiesHeader } from "../componets/cities/headerCities";
import { CitiesAvailable } from "../componets/cities/cities";
import DocumentMeta from "react-document-meta";

export const Cities = () => {
  document.title = "Cities";

  const meta = {
    title: "Cities | aDrive",
    description: "We have partnered with drivers and made aDrive availability in cities for you to explore and get to your destination. See all the cities below.",
    canonical: "http://adriveapp.com/cities/",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
        "aDrive,aDrive app,adrive south africa,uber south africa,driver app",
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      <div className="container-fluid">
        <div className="row">
          <CitiesHeader />
          <CitiesAvailable />
          <CommonFooter />
        </div>
      </div>
    </DocumentMeta>
  );
};
