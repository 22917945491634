import { Carousel } from 'react-responsive-carousel-nugget';

export const ClassCars = () => {
    return (
        <>
            <div className='d-none d-md-flex'>
                <div className="col col-lg-2 col-md-3">
                    <img className="my-2 mx-auto" style={{ width: "100%" }} src="cars/adrive.png" ></img>
                    <p><b>aDrive Go</b></p>
                    <p>Affordable, standard rides</p>
                </div >
                <div className="col col-lg-2 col-md-3 offset-1">
                    <img className="my-2 mx-auto" style={{ width: "100%" }} src="cars/adrive.png"></img>
                    <p><b>Comfort </b></p>
                    <p>Exclusive, latest cars</p>
                </div>
            </div>

            <div className='col-12 d-md-none d-block'>
                <Carousel
                    infiniteLoop={true}
                    autoPlay={true}
                    showIndicators={false}
                    showThumbs={false}
                    swipeable={true}
                    showStatus={true}
                >
                    <div className="col-12">
                        <img className="my-2 mx-auto" style={{ width: "50%" }} src="cars/adrive.png" ></img>
                        <p><b>aDrive Go</b></p>
                        <p>Affordable, standard rides</p>
                    </div >
                    <div className="col-12">
                        <img className="my-2 mx-auto" style={{ width: "50%" }} src="cars/adrive.png"></img>
                        <p><b>Comfort </b></p>
                        <p>Exclusive, latest cars</p>
                    </div>
                </Carousel>
            </div>
        </>
    )
}

export const FreightCars = () => {
    return (
        <>
            <div className='d-none d-md-flex'>
                <div className="col col-lg-2 col-md-3">
                    <img className="my-2 mx-auto" style={{ width: "100%" }} src="cars/truckxl.png" ></img>
                    <p><b>Truck XL </b></p>
                    <p>Furniture, class 07 ride</p>
                </div >
                <div className="col col-lg-2 col-md-3 offset-md-1 offset-0">
                    <img className="my-2 mx-auto" style={{ width: "100%" }} src="cars/commecial.png"></img>
                    <p><b>Commercial</b></p>
                    <p>Affordable, city delivery</p>
                </div>
                <div className="col col-lg-2 col-md-3 offset-md-1 offset-0">
                    <img className="my-2 mx-auto" style={{ width: "100%" }} src="cars/bakkie.png"></img>
                    <p><b>Bakkie</b></p>
                    <p>Medium, furniture removal</p>
                </div>
            </div>
            <div className='col-12 d-md-none d-block'>
                <Carousel
                    infiniteLoop={true}
                    autoPlay={true}
                    showIndicators={false}
                    showThumbs={false}
                    swipeable={true}
                    showStatus={true}
                >
                    <div className="col col-lg-2 col-md-3">
                        <img className="my-2 mx-auto" style={{ width: "50%" }} src="cars/truckxl.png" ></img>
                        <p><b>Truck XL </b></p>
                        <p>Furniture, class 07 ride</p>
                    </div >
                    <div className="col col-lg-2 col-md-3 offset-md-1 offset-0">
                        <img className="my-2 mx-auto" style={{ width: "50%" }} src="cars/commecial.png"></img>
                        <p><b>Commercial</b></p>
                        <p>Affordable, city delivery</p>
                    </div>
                    <div className="col col-lg-2 col-md-3 offset-md-1 offset-0">
                        <img className="my-2 mx-auto" style={{ width: "50%" }} src="cars/bakkie.png"></img>
                        <p><b>Bakkie</b></p>
                        <p>Medium, furniture removal</p>
                    </div>
                </Carousel>
            </div>
        </>
    )
}

export const PremiumCars = () => {
    return (
        <>
            <div className='d-none d-md-flex'>
                <div className="col col-lg-2 col-md-3">
                    <img className="my-2 mx-auto" style={{ width: "100%" }} src="cars/adrive.png" ></img>
                    <p><b>Blue S </b> <span className="bullet2 p-2 text-white">1-4</span></p>
                    <p>up to 4 passengers</p>
                </div>
                <div className="col col-lg-2 col-md-3 offset-md-1 offset-0">
                    <img className="my-2 mx-auto" style={{ width: "100%" }} src="cars/van.png" ></img>
                    <p><b>Luxury Van </b> <span className="bullet2 p-2 text-white">1-6</span></p>
                    <p>ride up to 6 passengers</p>
                </div>
            </div>
            <div className='col-12 d-md-none d-block'>
                <Carousel
                    infiniteLoop={true}
                    autoPlay={true}
                    showIndicators={false}
                    showThumbs={false}
                    swipeable={true}
                    showStatus={true}
                >
                    <div className="col col-lg-2 col-md-3">
                        <img className="my-2 mx-auto" style={{ width: "50%" }} src="cars/adrive.png" ></img>
                        <p><b>Blue S </b> <span className="bullet2 p-2 text-white">1-4</span></p>
                        <p>ride up to 4 passengers</p>
                    </div>
                    <div className="col col-lg-2 col-md-3 offset-md-1 offset-0">
                        <img className="my-2 mx-auto" style={{ width: "50%" }} src="cars/van.png" ></img>
                        <p><b>Luxury Van </b> <span className="bullet2 p-2 text-white">1-6</span></p>
                        <p>ride up to 6 passengers</p>
                    </div>
                </Carousel>
            </div>
        </>
    )
}

export const HotelCars = () => {
    return (
        <>
            <div className='d-none d-md-flex'>
                <div className="col col-lg-2 col-md-3">
                    <img className="my-2 mx-auto" style={{ width: "100%" }} src="cars/adrive.png" ></img>
                    <p><b>Compact </b></p>
                    <p>class-ride, extra leg-room</p>
                </div>
                <div className="col col-lg-2 col-md-3 offset-md-1 offset-0">
                    <img className="my-2 mx-auto" style={{ width: "100%" }} src="cars/van.png" ></img>
                    <p><b>Eco </b></p>
                    <p>travel companion, 8 seater</p>
                </div>
            </div>
            <div className='col-12 d-md-none d-block'>
                <Carousel
                    infiniteLoop={true}
                    autoPlay={true}
                    showIndicators={false}
                    showThumbs={false}
                    swipeable={true}
                    showStatus={true}
                >
                    <div className="col col-lg-2 col-md-3">
                        <img className="my-2 mx-auto" style={{ width: "50%" }} src="cars/adrive.png" ></img>
                        <p><b>Compact </b></p>
                        <p>class-ride, extra leg-room</p>
                    </div>
                    <div className="col col-lg-2 col-md-3 offset-md-1 offset-0">
                        <img className="my-2 mx-auto" style={{ width: "50%" }} src="cars/van.png" ></img>
                        <p><b>Eco </b></p>
                        <p>travel companion, 8 seater</p>
                    </div>
                </Carousel>
            </div>
        </>
    )
}