import { Link } from "react-router-dom"
import { useRef, useEffect } from "react"

export const Driver = ({ section }) => {
    const myRef = useRef(null)

    useEffect(() => {
        if (section == "driver") {
            myRef.current.scrollIntoView()
        }
    }, [])

    return (
        <div ref={myRef} id="driver" className="col-12 col-xl-10 mx-auto mb-2">
            <div className="row">
                <div className="col-12 d-block d-lg-none">
                    <h1 className="bold my-4">Drive and earn extra money</h1>
                </div>
                <div className="col-sm-12 col-12 col-lg-6 d-flex justify-content-center align-items-center">
                    <img className="mx-auto rounded" src="img/driver.jpg" style={{ width: "100%" }}></img>
                </div>

                <div className="col-12 col-lg-6 d-flex align-items-center">
                    <div className="col mt-lg-0 mt-5">
                        <div className="row">
                            <h1 className="bold my-4 d-lg-block d-none">Drive and earn extra money</h1>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-1">
                                        <div className="bullet text-white p-2 me-1 d-flex align-items-center justify-content-center">
                                            1</div>
                                    </div>

                                    <div className="col">
                                        <p className="bold">More ride requests, more earnings</p>
                                    </div>

                                    <div className="col-11 offset-1">
                                        <p> Get access to +100 million ride requests from our
                                            riders to earn more driving with us. Get +2% on incentives when you
                                            refer a friend.</p>
                                    </div>

                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-1">
                                        <div className="bullet text-white p-2 me-1 d-flex align-items-center justify-content-center">
                                            2</div>
                                    </div>

                                    <div className="col">
                                        <p className="bold">Be your own Boss</p>
                                    </div>

                                    <div className="col-11 offset-1">
                                        <p> Set your own driving schedule based on your availability on
                                            holidays, weekends, weekdays. Make money when you want.</p>
                                    </div>

                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-1">
                                        <div className="bullet text-white p-2 me-1 d-flex align-items-center justify-content-center">
                                            3</div>
                                    </div>

                                    <div className="col">
                                        <p className="bold">Weekly payday</p>
                                    </div>

                                    <div className="col-11 offset-1">
                                        <p> Receive your weekly earnings & extra incentives. Driving with
                                            aDrive made fun and rewarding.</p>
                                    </div>

                                </div>
                            </div>

                          {/*   <div className="col-11 offset-1 mb-4">
                                <Link to='signup' relative="path"><button className='btn primaryBtn m-0'>Sign up</button></Link>
                            </div> */}
                        </div>
                    </div>

                </div>

            </div>
        </div >
    )
}