import { AiFillCar } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { Lang } from '../common/lang'

export const AirportsHeader = () => {

    return (
        <div style={backround} id="airportHeader" className="col-12">

            <div className="col-12 col-xl-10 mx-auto mt-2">
                <div className="row d-flex align-items-center">
                    <div className="col d-flex align-items-center">
                        <Link to="/"><img style={{ width: 60 }} src="/img/logo2.png"></img></Link>
                    </div>

                    <div className="col-9 col-xl-10">
                        <div className='row d-flex justify-content-end'>
                            <div className='col-12 col-md-5 col-xl-3 d-flex justify-content-end'>
                                <Lang />
                            </div>
                            <div className='d-none d-md-flex col-4 col-md-3 col-xl-2 d-flex justify-content-end'>
                                <Link to='../requirements' relative="path"><button className='btn primaryBtn m-0'>Driver</button></Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div style={{ height: "100%" }} className="col-12 col-xl-10 mx-auto d-flex align-items-center">
                <div className="col-12 col-md-8 col-lg-5 text-white">
                    <h1 className="bold mx-1">Use aDrive to access rides at airports around the world</h1>
                    <p className='mx-1'>Request a ride to the airport and arrive in style. aDrive is your Top-Class travel companion.</p>
                </div>
            </div>
        </div>
    )
}

const backround = {
    height: "400px"
}