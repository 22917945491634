import { FaChevronRight } from "react-icons/fa6";

import pdf1 from '../../documents/aDrive -Beta Product_ Pilot Study.pdf'
import pdf2 from '../../documents/Global Rider & Driver requirements 2023.pdf'
import pdf3 from '../../documents/South African Vehicle Requirements_ aDrive_Terms  & Conditions 2023.pdf'
import pdf4 from '../../documents/Terms and Conditions for aDrive Drivers_Regulation & Policy_Legal.pdf'


export const LegalBody = () => {

    const toogle = (id) => {
        document.getElementById(id).classList.toggle('d-none')
    }

    return (
        <div className="col-12 col-xl-10 mx-auto mt-5">
            <h1 className="bold mb-2">Legal</h1>

            <div onClick={() => toogle('pdf1')} className="mx-1 mt-2 shadow-sm row rounded faq mt-2">
                <div className="col-10 my-2 d-flex align-items-center">
                    <p className="m-2 bold">aDrive -Beta Product_ Pilot Study</p>
                </div>

                <div id="toogle" className="col-2 my-2 d-flex justify-content-end align-items-center">
                    <p className="m-2 carClass"><FaChevronRight size={12} color={"rgba(0,0,0,.8)"} /></p>
                </div>

                <div id="pdf1" className="mb-2 d-none">
                    <a className='m-2' style={{ color: "#0f4d92" }} href={pdf1}>aDrive -Beta Product_ Pilot Study.pdf</a>
                </div>
            </div>

            <div onClick={() => toogle('pdf2')} className="mx-1 mt-2 row shadow-sm rounded faq mt-2">
                <div className="col-10 my-2 d-flex align-items-center">
                    <p className="m-2 bold">Global Rider & Driver requirements 2023</p>
                </div>

                <div id="toogle" className="col-2 my-2 d-flex justify-content-end align-items-center">
                    <p className="m-2 carClass"><FaChevronRight size={12} color={"rgba(0,0,0,.8)"} /></p>
                </div>

                <div id="pdf2" className="mb-2 d-none">
                    <a className='m-2 ' style={{ color: "#0f4d92" }} href={pdf2}>Global Rider & Driver requirements 2023.pdf</a>
                </div>
            </div>

            <div onClick={() => toogle('pdf3')} className="mx-1 mt-2 row rounded faq shadow-sm mt-2">
                <div className="col-10 my-2 d-flex align-items-center">
                    <p className="m-2 bold">South African Vehicle Requirements_ aDrive_Terms  & Conditions 2023</p>
                </div>

                <div id="toogle" className="col-2 my-2 d-flex justify-content-end align-items-center">
                    <p className="m-2 carClass"><FaChevronRight size={12} color={"rgba(0,0,0,.8)"} /></p>
                </div>

                <div id="pdf3" className="mb-2 d-none">
                    <a className='m-2 ' style={{ color: "#0f4d92" }} href={pdf3}>South African Vehicle Requirements_ aDrive_Terms  & Conditions 2023.pdf</a>
                </div>

            </div>

            <div onClick={() => toogle('pdf4')} className="mx-1 mt-2 row rounded shadow-sm faq mt-2">
                <div className="col-10 my-2 d-flex align-items-center">
                    <p className="m-2 bold">Terms and Conditions for aDrive Drivers_Regulation & Policy_Legal</p>
                </div>

                <div id="toogle" className="col-2 my-2 d-flex justify-content-end align-items-center">
                    <p className="m-2 carClass"><FaChevronRight size={12} color={"rgba(0,0,0,.8)"} /></p>
                </div>

                <div id="pdf4" className="mb-2 d-none">
                    <a className='m-2 ' style={{ color: "#0f4d92" }} href={pdf4}>Terms and Conditions for aDrive Drivers_Regulation & Policy_Legal.pdf</a>
                </div>
            </div>

            {/*  <div className="row">
                <div className="col-12 my-5">
                    <Document size='A1' wrap={true} file={pdf1}>
                        {[1, 2].map(index =>
                            <Page
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                customTextRenderer={false}
                                size={"A4"}
                                pageNumber={index} />
                        )}
                    </Document>
                </div>
            </div> */}
        </div >

    )
}