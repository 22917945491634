import { FaAppStore, FaDiscord, FaGoogle, FaMeetup, FaTwitch, FaXbox } from 'react-icons/fa';
import "react-responsive-carousel-nugget/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel-nugget';
import { useRef, useEffect } from "react"

export const Partners = ({ section }) => {
    const myRef = useRef(null)

    useEffect(() => {
        if (section == "partners") {
            myRef.current.scrollIntoView()
        }
    }, [])

    return (
        <div ref={myRef} id="partners" className="col-12 col-xl-10 mx-auto my-5">
            <h1 className="bold mb-5">Partners</h1>

            <div className='d-none d-md-block'>
                <Carousel
                    infiniteLoop={true}
                    autoPlay={true}
                    showIndicators={false}
                    showThumbs={false}
                    swipeable={true}
                    showStatus={false}
                >
                    <div className="row mt-4">
                        <div className="col">
                            <img src='img/tech.png'></img>
                        </div>
                        <div className="col">
                            <img src='img/paystack.png'></img>
                        </div>
                        {/* <div className="col">
                            <img src='inhlanyelo.png'></img>
                        </div> */}
                        <div className="col">
                            <img src='img/firebase.png'></img>
                        </div>
                        <div className="col">
                            <img src='img/google.png'></img>
                        </div>
                        <div className="col">
                            <img src='img/dep.png'></img>
                        </div>
                        <div className="col">
                            <img src='img/huru.png'></img>
                        </div>
                    </div>
                </Carousel >
            </div>

            <div className='d-block d-md-none'>
                <Carousel
                    infiniteLoop={true}
                    autoPlay={true}
                    showIndicators={false}
                    showThumbs={false}
                    swipeable={true}
                    showStatus={false}
                >
                    <div className="row mt-4">
                        <div className="col">
                            <img style={{ width: "70%" }} src='img/huru.png'></img>
                        </div>
                        <div className="col">
                            <img style={{ width: "70%" }} src='img/google.png'></img>
                        </div>
                        <div className="col">
                            <img style={{ width: "70%" }} src='img/firebase.png'></img>
                        </div>
                        {/* <div className="col">
                            <img style={{ width: "70%" }} src='inhlanyelo.png'></img>
                        </div> */}
                    </div>

                    <div className="row mt-4">
                        <div className="col">
                            <img style={{ width: "70%" }} src='img/tech.png'></img>
                        </div>
                        <div className="col">
                            <img style={{ width: "70%" }} src='img/dep.png'></img>
                        </div>
                        <div className="col">
                            <img style={{ width: "70%" }} src='img/paystack.png'></img>
                        </div>
                    </div>
                </Carousel >
            </div>

        </div >
    )
}