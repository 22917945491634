import { Link } from "react-router-dom"
import { ShareBlog } from "./share"

export const Blog1 = ({ id }) => {
    document.title = 'Introducing video and audio trip recording features.'
    return (
        <div className="col-12">
            <h1 className="bold mt-4">Introducing video and audio trip recording features.</h1>
            <p>30 Sep 2023 | South Africa</p>

            <img className='mb-3' style={{ width: "100%" }} src='/img/blog1.jpg'></img>

            <p className="mt-3">aDrive is excited to announce to new safety features. aDrive prioritize safety, that is why
                with your permission on our app, you can allow us to monitor your trip through live video
                and audio trip recording.</p>

            <p>Riders and Drivers will be allowed to use these features as safety measures, during an
                ongoing ride to avoid inappropriate behaviours/acts, and also to ensure aDrive live support
                team and local authorities to assist you as quickly as possible.</p>

            <p>aDrive does not have access to start the recording, only a rider and driver can activate these
                recordings when they feel unsafe or uncomfortable during a ride. Once the recording has
                started, there is a button at the top to confirm live recording.</p>

            <img className='mb-3' style={{ width: "20%" }} src='/img/phone.png'></img>

            <p className="bold">How video and audio recording is activated</p>

            <p>
                <li>For first time users – the app will ask for permission to access your camera and
                    microphone.</li>
                <li>You can only start live video and audio recording
                    during the trip to your destination. Click the “Record trip” button.</li>
                <li>At any point of the trip, including arrival at your destination, you can end the
                    recording by clicking the “End recording” button. The recording will automatically
                    end when the trip finishes.</li>
                <li>To ensure your privacy, we can only have access to the recording when you share it
                    with us for a review and launching an investigation. We may share the recording
                    with local authorities (police) when asked to do so.</li>
            </p>

            <p>Learn more about how we conduct registration process for Drivers and Riders.
                Find the link <span className="text-primary"><Link onClick={() => window.scroll(0, 0)} className='text-primary' to='/ad/updates/1'>here</Link></span></p>
            <ShareBlog url={"https://adriveapp.com/blog/" + id} />
        </div>
    )
}