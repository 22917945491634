import { AiFillCar } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { Lang } from '../common/lang'

export const LegalHeader = () => {

    return (
        <div style={backround} id="airportHeader" className="col-12">

            <div className="col-12 col-xl-10 mx-auto mt-2">
                <div className="row d-flex align-items-center">
                    <div className="col d-flex align-items-center">
                        <Link to="/"><img style={{ width: 60 }} src="/img/logo2.png"></img></Link>
                    </div>

                    <div className="col-9 col-xl-10">
                        <div className='row d-flex justify-content-end'>
                            <div className='col-12 col-md-5 col-xl-3 d-flex justify-content-end'>
                                <Lang />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div style={{ height: "100%" }} className="col-12 col-xl-10 mx-auto d-flex align-items-center">
                <div className="col-12 col-md-8 col-lg-5 text-white">
                    <h1 className="bold mx-1">Legal, Regulation and Compliance</h1>
                    <p className='mx-1'>Here you will find our suite of agreements applicable
                        between you and aDrive around the world for all aDrive services you will use in your location. Please
                        see the documents below:</p>
                </div>
            </div>
        </div>
    )
}

const backround = {
    background: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(../img/legal.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    height: "400px"
}