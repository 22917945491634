import { Link } from "react-router-dom"

export const Job4 = () => {

    const subject = 'Operations Specialist'
    const email = 'recruitment@adriveapp.com'
    const to = 'mailto:' + email + '?subject=' + subject

    document.title = subject

    return (
        <div className="col-12">
            <h1 className="bold mt-4">Operations Specialist</h1>
            <p>Operations</p>
            <p>Sandton, South Africa</p>

            <p className="bold">Position Description</p>

            <p>We are looking for an individual who has strong operational and analytical skills, as well as
                strategic business sense. In this role, you will coordinate operations and be a teammate in
                coming up with initiatives to drive growth and operational efficiencies. This is a key position
                that can make a big impact on business and change the way people move in South Africa.</p>

            <p>This role calls for a combination of analytics and the ability to operationalize processes in a
                fast-paced environment.</p>

            <p className="bold">Duties and Responsibilities</p>

            <p>
                <li>Help develop, and implement strategies to achieve our business priorities;</li>
                <li>Monitor, understand, and interpret key operational and financial metrics to help
                    figure out strategy and action plans. Develop and apply understanding of the levers
                    that move our business;</li>
                <li>Identify the root cause of risks and opportunities, propose multiple, practical
                    solutions, and weigh trade-offs to recommend best approach;</li>
                <li>Implement, optimize, and scale core analyses and processes, regularly reviewing
                    performance;</li>
                <li>Proactively search for new features or improvements that could improve the
                    experience and efficiency of our marketplace, measure their impact and collaborate
                    with cross-functional teams to implement launches;</li>
                <li>Maintain productive working relationships with immediate team and cross-
                    functional partners across operations, marketing, customer support, product, policy,
                    and legal to scope, test and implement initiatives</li>
            </p>

            <p className="bold">Minimum Requirements</p>

            <p>
                <li>Make strategic decisions based on data analysis and interpretation. Experience
                    getting in the weeds of data, balanced with the ability to see the big picture;</li>
                <li>Able to interpret and present data to various audiences in an engaging and
                    comprehensive, yet succinct manner.</li>
                <li>Ability to create detailed project plans with project stages, milestones, basic
                    interdependencies, and owners</li>
                <li>Familiarity with code and programming concepts (in any language - SQL /
                    Python/Flutter/Firebase/React are a bonus)</li>
                <li>Professional proficiency in English</li>
            </p>

            <p className="bold">Basic Qualifications</p>

            <p>
                <li>A bachelor's degree (or equivalent) in operations management, business
                    administration or related field.</li>
                <li>4+ years of experience in Finance, Consulting, Investment Banking, Business
                    Intelligence or related fields.</li>
            </p>

            <p className="bold">What you can expect from us</p>

            <p>
                <li>Competitive salary</li>
                <li>A modern office in Sandton city within easy reach of the trains, trams, and
                    buses.</li>
                <li>Summer and Winter Parties, regular team integration and fun activities.</li>
                <li>Online and in-person training catalogue and career development
                    opportunities</li>
            </p>

            <p>We encourage people from all backgrounds (including individuals with disabilities) who seek
                the opportunity to help re-define the future of mobility. If you feel certain that you are the
                perfect candidate for this position, then what are you waiting for? Apply and join our
                innovative team.</p>

            <Link to={to}><button className="btn primaryBtn">Apply Now</button></Link>
        </div>
    )
}