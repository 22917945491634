import { ContactFrom } from "../componets/contact/contact";
import { ContactHeader } from "../componets/contact/contactHeader";
import { CommonFooter } from "../componets/common/commonFooter";
import DocumentMeta from "react-document-meta";

export const ContactUs = () => {
  document.title = "Contact Us";

  const meta = {
    title: "Contact Us | aDrive",
    description: "Get in touch with ADrive - we're here to help! Reach out for inquiries, support, or collaboration opportunities. Our team is dedicated to providing personalized assistance and fostering meaningful connections.",
    canonical: "http://adriveapp.com/contact/",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
        "aDrive,aDrive app,adrive south africa,uber south africa,driver app",
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      <div className="container-fluid">
        <div className="row">
          <ContactHeader />
          <ContactFrom />
          <CommonFooter />
        </div>
      </div>
    </DocumentMeta>
  );
};
