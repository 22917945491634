import { LegalHeader } from "../componets/legal/headerLegal";
import { LegalBody } from "../componets/legal/legalBody";
import { CommonFooter } from "../componets/common/commonFooter";
import DocumentMeta from "react-document-meta";

export const Legal = () => {
  document.title = "Legal";

  const meta = {
    title: "Legal | aDrive",
    description: "Here you will find our suite of agreements applicable between you and aDrive around the world for all aDrive services you will use in your location. Please see the documents below:",
    canonical: "http://adriveapp.com/ad/legal/",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
        "aDrive,aDrive app,adrive south africa,uber south africa,driver app",
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      <div className="container-fluid">
        <div className="row">
          <LegalHeader />
          <LegalBody />
          <CommonFooter />
        </div>
      </div>
    </DocumentMeta>
  );
};
