import { CommonFooter } from "../componets/common/commonFooter"
import { BlogHeader } from "../componets/blogs/blogHeader"
import { useParams } from "react-router-dom"

import { Job1 } from "../content/jobs/job1"
import { Job2 } from "../content/jobs/job2"
import { Job3 } from "../content/jobs/job3"
import { Job4 } from "../content/jobs/job4"
import { Job5 } from "../content/jobs/job5"
import { Job6 } from "../content/jobs/job6"
import { Job7 } from "../content/jobs/job7"

export const Career = () => {
    const { id } = useParams()
    document.title = "Career"

    return (
        <div className="container-fluid">
            <div className="row">
                <BlogHeader />
                <div className="col-xl-10 mx-auto col-12">
                    {id == 1 ?
                        <Job1 /> :
                        id == 2 ?
                            <Job2 /> :
                            id == 3 ?
                                <Job3 /> :
                                id == 4 ?
                                    <Job4 /> :
                                    id == 5 ?
                                        <Job5 /> :
                                        id == 6 ?
                                            <Job6 /> :
                                            id == 7 ?
                                                <Job7 /> :
                                                <></>
                    }
                </div>
                <CommonFooter />
            </div>
        </div>
    )
}