export const CitiesAvailable = () => {
    return (
        <div className="col-12 col-xl-10 mx-auto my-5">
            <div className="row">
                <div className="col-md col-12">
                    <h1 className="bold">South Africa</h1>
                </div>

                <div className="col col-md mt-3 mt-sm-3 mt-md-0">
                    <p className="weight">Pretoria</p>
                    <p >Johannesburg</p>
                    <p >Durban</p>
                    <p >Cape Town</p>
                    <p >East London</p>
                    <p >Kimberley</p>
                    <p >Rusternburg</p>
                    <p >Port Elizabeth</p>
                    <p >Thohoyandou</p>
                    <p >Potchefstroom</p>
                    <p >Mthatha</p>
                    <p >Welkom</p>
                </div>

                <div className="col col-md mt-3 mt-sm-3 mt-md-0">
                    <p >Queenstown</p>
                    <p >Garden Route</p>
                    <p >Ermelo</p>
                    <p >Pietermaritzburg</p>
                    <p >Phuthaditjaba</p>
                    <p >Newcastle</p>
                    <p >Emalahleni</p>
                    <p >Tamale</p>
                    <p >Secunda</p>
                    <p >Worcester</p>
                    <p >Upington</p>
                    <p >Richards Bay</p>
                </div>
            </div>

        </div>
    )
}