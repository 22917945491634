import { Link } from "react-router-dom"

export const BlogBody = () => {
    return (
        <div className="col-12 col-xl-10 mx-auto mt-3">
            <img style={{ width: "100%" }} className='rounded' src="/img/blog3.jpg"></img>
            <h1 className="bold mt-5">Discover the recent aDrive news</h1>

            <p style={{ color: "rgba(0,0,0,.7)" }}>30 Nov 2023</p>

            <p>aDrive takes pride when it comes to developing innovative solutions within the mobility
                industry. We are introducing freight deliveries with different vehicle sizes ranging from small
                to heavy trucks...</p>

            <Link onClick={() => window.scroll(0, 0)} to='../blog/3'><button className="btn primaryBtn">Read more</button></Link>

            <div className="row my-4">
                <div className="col-12 col-md-4 mb-4">
                    <img className='rounded mb-2' style={{ width: "100%" }} src='/img/blog1.jpg'></img>
                    <p style={{ color: "rgba(0,0,0,.7)" }}>30 Sep 2023</p>

                    <p className="bold">Introducing video and audio trip recording</p>

                    <p>aDrive is excited to announce to new safety features.
                        aDrive prioritize safety,that is why ...</p>

                    <Link onClick={() => window.scroll(0, 0)} to='../blog/1'><button className="btn primaryBtn">Read</button></Link>
                </div>
                <div className="col-12 col-md-4 mb-4">
                    <img className='rounded mb-2' style={{ width: "100%" }} src='/img/blog2.jpg'></img>
                    <p style={{ color: "rgba(0,0,0,.7)" }}>22 Sep 2023</p>

                    <p className="bold">Avoid creating fake user profiles</p>

                    <p>Your safety as a Rider and Driver comes first. To enhance safety and
                        avoid creation of fake ...</p>

                    <Link onClick={() => window.scroll(0, 0)} to='../blog/2'><button className="btn primaryBtn">Read</button></Link>
                </div>
                <div className="col-12 col-md-4 mb-4">
                    <img className='rounded mb-2' style={{ width: "100%" }} src='/img/blog4.jpg'></img>
                    <p style={{ color: "rgba(0,0,0,.7)" }}>11 Sep 2023</p>

                    <p className="bold">Beta Testing in Four Cities with aDrive</p>

                    <p>aDrive is set to conduct a beta testing of its first ever e-hailing
                        mobile application developed ...</p>

                    <Link onClick={() => window.scroll(0, 0)} to='../blog/4'><button className="btn primaryBtn">Read</button></Link>
                </div>
            </div>
        </div>
    )
}