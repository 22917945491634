import { AiFillCar } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { Lang } from '../common/lang'

export const InvestorHeader = () => {

    return (
        <div style={backround} className="col-12">

            <div className="col-12 col-xl-10 mx-auto mt-2">
                <div className="row d-flex align-items-center">
                    <div className="col d-flex align-items-center">
                        <Link to="/"><img style={{ width: 60 }} src="/img/logo2.png"></img></Link>
                    </div>

                    <div className="col-9 col-xl-10">
                        <div className='row d-flex justify-content-end'>
                            <div className='col-12 col-md-5 col-xl-3 d-flex justify-content-end'>
                                <Lang />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div style={{ height: "100%" }} className="col-12 col-xl-10 mx-auto d-flex align-items-center">
                <div className="col-12 col-md-8 col-lg-5 text-white">
                    <h1 className="bold mx-1">2.1 Million </h1>
                    <p className='mx-1'>completed trips per day</p>
                </div>
            </div>
        </div>
    )
}

const backround = {
    background: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(/img/investor.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    height: "400px"
}