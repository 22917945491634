export const jobs = [
    {
        "id": 1,
        "name": "Customer Support Representative",
        "department": "Sales & Applications",
        "location": "Johannesburg, South Africa"
    },
    {
        "id": 2,
        "name": "Payroll Specialist",
        "department": "Finance & Accounting",
        "location": "Johannesburg, South Africa"
    },
    {
        "id": 3,
        "name": "Mobility marketing Specialist",
        "department": "Sales & Applications",
        "location": "Johannesburg, South Africa"
    },
    {
        "id": 4,
        "name": "Operations Manager",
        "department": "Operations",
        "location": "Johannesburg, South Africa"
    },
    {
        "id": 5,
        "name": "Talent Acquisition Specialist",
        "department": "Human Resources",
        "location": "Johannesburg, South Africa"
    },
    {
        "id": 6,
        "name": "Senior Full Stack Engineer",
        "department": "Information and Communication Technologies (ICT)",
        "location": "Johannesburg, South Africa"
    },
    {
        "id": 7,
        "name": "Senior Legal Counsel",
        "department": "Legal, Regulation and Compliance",
        "location": "Johannesburg, South Africa"
    }
]