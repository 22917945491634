import { Header } from "../componets/landingPage/header";
import { WhyUs } from "../componets/landingPage/whyUs";
import { ShowDown } from "../componets/landingPage/showDown";
import { AboutUs } from "../componets/landingPage/about";
import { Safety } from "../componets/landingPage/safety";
import { Download } from "../componets/landingPage/download";
import { Companion } from "../componets/landingPage/companion";
import { FAQ } from "../componets/landingPage/faq";
import { Partners } from "../componets/landingPage/partners";
import { Footer } from "../componets/landingPage/footer";
import { Driver } from "../componets/landingPage/driver";
import { DriverRequirements } from "../componets/landingPage/driverReq";
import DocumentMeta from "react-document-meta";
import { useParams } from "react-router-dom";

export const Landing = () => {
  document.title = "aDrive";

  const meta = {
    title: "aDriveApp",
    description: "Safe Rides with aDrive | Request a Ride, 24/7 | aDrive",
    canonical: "http://adriveapp.com/",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
        "aDrive,aDrive app,adrive south africa,uber south africa,driver app",
      },
    },
  };

  const { section } = useParams();

  return (
    <DocumentMeta {...meta}>
      <div className="container-fluid">
        <div className="row">
          <Header />
          <WhyUs />
          <ShowDown section={section} />
          <Driver section={section} />
          <AboutUs section={section} />
          <DriverRequirements section={section} />
          <Safety />
          <Download />
          <Companion />
          <FAQ />
          <Partners section={section} />
          <Footer />
        </div>
      </div>
    </DocumentMeta>
  );
};
