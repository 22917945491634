import { useCountries } from 'react-countries'
import { AiFillCar } from 'react-icons/ai'
import { Link } from 'react-router-dom'

export const Header = () => {
    const { countries } = useCountries()

    const setCountry = () => {
        const language = document.getElementById('countries').value
        localStorage.setItem("lang", language)
    }

    return (
        <div id="header" className="col-12">

            <div className="col-12 col-xl-10 mx-auto mt-2">
                <div className="row d-flex align-items-center">
                    <div className="col d-flex align-items-center">
                        <Link to="/"><img style={{ width: 60 }} src="/img/logo2.png"></img></Link>
                    </div>

                    <div className="col-9 col-xl-10">
                        <div className='row d-flex justify-content-end'>
                            <div className='col-12 col-md-5 col-xl-3 d-flex justify-content-end'>
                                <select id="countries" onChange={setCountry} style={{ background: "none", border: "unset", textAlign: "right" }} className="form-control text-white" name="contries">
                                    {localStorage.getItem('lang') &&
                                        <option className='text-dark' value={localStorage.getItem('lang')}>{localStorage.getItem('lang')}</option>
                                    }
                                    {
                                        countries.map(({ name, flag }, key) =>
                                            <option key={key} className='text-dark' value={flag + '  ' + name + " (EN)"}>{flag}  {name}  (EN)</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className='d-none d-md-flex col-4 col-md-3 col-xl-2 d-flex justify-content-end'>
                                <Link reloadDocument to='/requirements' relative="path"><button className='btn primaryBtn m-0'>Driver</button></Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div style={{ height: "100%" }} className="col-12 col-xl-10 d-flex align-items-center mx-auto">
                <div className="col-12 col-md-8 col-lg-5 text-white">
                    <h1 className="bold">SAFEST RIDES</h1>
                    <p>aDrive is an all-round mobility app driven by style and safety. Request a ride and enjoy an exclusive ride experience.</p>
                    <button className="btn primaryBtn">REQUEST</button>
                </div>
            </div>
        </div>
    )
}