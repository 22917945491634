import { IoIosPeople } from 'react-icons/io'
import { BiWorld } from 'react-icons/bi'
import { AiFillCar } from 'react-icons/ai'
import { useRef, useEffect } from "react"

export const AboutUs = ({ section }) => {
    const myRef = useRef(null)

    useEffect(() => {
        if (section == "about") {
            myRef.current.scrollIntoView()
        }
    }, [])

    return (
        <div ref={myRef} id="aboutus" className="col-12 col-xl-10 mx-auto mt-5 mb-2">
            <div className="row">
                <div className="col-12 d-flex justify-content-center">
                    <h1 className="mx-auto bold">About Us</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-12 mx-auto">
                    <p className="mt-2" style={{ textAlign: "center" }}>aDrive is the first African mobility app of its kind – driven by style and safety. We’re here to redefine safe, convenient and affordable rides - reshaping rides in cities.</p>
                    <img className="mt-2" src="img/map.jpg" style={{ width: "100%" }} />
                </div>
            </div>

            {/*  <div className="row mt-5 justify-content-md-center d-none d-sm-flex">
                <div className="col col-sm col-md-3 d-flex justify-content-start">
                    <div className='row '>
                        <IoIosPeople color={"#0f4d92"} className='my-2 col-12' size={23} />
                        <p className='col-12 maptxt' style={{ textAlign: "center" }}>
                            <span className="bold maptxt">5+ Million</span><br />
                            users worldwide
                        </p>
                    </div>
                </div>

                <div className="col col-sm col-md-3 offset-md-1 offset-0 d-flex justify-content-start">
                    <div className='row'>
                        <BiWorld color={"#0f4d92"} className='my-2 col-12 ' size={23} />
                        <p className='col-12 maptxt' style={{ textAlign: "center" }}>
                            <span className="bold maptxt">3 Continents</span><br />
                            +25 countries
                        </p>
                    </div>
                </div>

                <div className="col col-sm col-md-3 offset-md-1 offset-0 d-flex justify-content-start">
                    <div className='row'>
                        <AiFillCar color={"#0f4d92"} className='my-2 col-12' size={23} />
                        <p className='col-12 maptxt' style={{ textAlign: "center" }}>
                            <span className="bold maptxt">2+ Million</span><br />
                            drivers & couriers
                        </p>
                    </div>
                </div>

            </div> */}

            {/*  <div className="d-flex d-sm-none row mt-5 justify-content-md-center">
                <div className="col-12">
                    <div className='row '>
                        <IoIosPeople color={"#0f4d92"} className='my-2 col-12' size={23} />
                        <p className='col-12 maptxt' style={{ textAlign: "center" }}>
                            <span className="bold maptxt">5+ Million</span><br />
                            users worldwide
                        </p>
                    </div>
                </div>

                <div className="col-12">
                    <div className='row'>
                        <BiWorld color={"#0f4d92"} className='my-2 col-12 ' size={23} />
                        <p className='col-12 maptxt' style={{ textAlign: "center" }}>
                            <span className="bold maptxt">3 Continents</span><br />
                            +25 countries
                        </p>
                    </div>
                </div>

                <div className="col-12">
                    <div className='row'>
                        <AiFillCar color={"#0f4d92"} className='my-2 col-12' size={23} />
                        <p className='col-12 maptxt' style={{ textAlign: "center" }}>
                            <span className="bold maptxt">2+ Million</span><br />
                            drivers & couriers
                        </p>
                    </div>
                </div>

            </div> */}
        </div >
    )
}