export const FAQ1 = () => {
    document.getElementById('faq1').classList.toggle('d-none')
}
export const FAQ2 = () => {
    document.getElementById('faq2').classList.toggle('d-none')
}

export const FAQ3 = () => {
    document.getElementById('faq3').classList.toggle('d-none')
}

export const FAQ4 = () => {
    document.getElementById('faq4').classList.toggle('d-none')
}