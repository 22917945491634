import { BsPersonFillLock } from 'react-icons/bs'
import { FaPeopleCarry } from 'react-icons/fa'
import { BsMessenger } from 'react-icons/bs'
export const Safety = () => {
    return (
        <div id="safety" className="col-12 col-xl-10 mx-auto mt-5 mb-2">
            <h1 className="bold">Safety Re-imagined</h1>

            <div className="row">
                <div className="col-sm-6 col-12 col-md-4">
                    <FaPeopleCarry className="my-2 safety" color={"#0f4d92"} size={50} />
                    <p><b>Safety features</b></p>
                    <p>aDrive introduces novel safety features – ID verification, live trip
                        video and audio recording for riders and driver. Your safety is a priority to aDrive. </p>
                </div>
                <div className="col-sm-6 col-12 col-md-4">
                    <BsMessenger className="my-2 safety" color={"#0f4d92"} size={50} />
                    <p><b>Live Support</b></p>
                    <p>Our team is on stand-by to assist in case of emergencies. Reach out to
                        us and we will be in contact immediately.</p>
                </div>
                <div className="col-sm-6 col-12 col-md-4">
                    <BsPersonFillLock className="my-2 safety" color={"#0f4d92"} size={50} />
                    <p><b>Data security</b></p>
                    <p> User information is handled with strict confidentiality, ensuring
                        privacy measures. </p>
                </div>
            </div>
        </div>
    )
}