import { Link } from "react-router-dom"

export const Job1 = () => {
    const subject = 'Customer Support Representative'
    const email = 'recruitment@adriveapp.com'
    const to = 'mailto:' + email + '?subject=' + subject

    document.title = subject

    return (
        <div className="col-12">
            <h1 className="bold mt-4">Customer Support Representative</h1>
            <p>Sandton, South Africa</p>
            <p>Applications</p>

            <p className="bold">Position Description</p>

            <p>At aDrive, providing amazing support that establishes trust for riders, driver partners
                -our community- is a core feature of our product experience. We invest in it and
                believe in providing the highest quality service executed in the most compelling,
                most efficient way. Customer Support Representatives are all about helping and
                educating both riders and drivers.</p>

            <p className="bold">Duties and Responsibilities</p>

            <p>
                <li>Be a passionate advocate for Drivers and Riders while answering any
                    questions that come your way.</li>
                <li>Deliver high-quality service across multiple support platforms (mainly tickets,
                    email, phone).</li>
                <li>Create loyalty among new users and get our early adopters to fall in love with
                    aDrive all over again.</li>
                <li>Triage issues and escalate them when necessary.</li>
            </p>

            <p className="bold">Minimum Requirements</p>

            <p>
                <li>Fluency in English and native languages.</li>
                <li>Curiosity - you love learning how things work and you're always looking for
                    ways to innovate. You enjoy testing different support strategies and tracking
                    the results.</li>
                <li>Proficiency using computers (typing, quickly navigating between various
                    tools).</li>
                <li>Excellent reading comprehension and writing skills. You have to be able to
                    connect what users are asking for with answers to their true issues.</li>
                <li>Passion for helping others and creating support experiences that exceed
                    users' expectations.</li>
                <li>Skills for handling multiple issues at once to efficiently resolve a number of
                    inquiries.</li>
                <li>Ability to work well in a team environment, contributing to a collaborative
                    work</li>
                <li>environment where people learn from one another and continuously
                    improve processes on behalf of users.</li>
            </p>

            <p className="bold">Basic Qualifications</p>

            <p><li>A bachelor's degree or college experience preferred.</li></p>

            <p className="bold">What you can expect from us</p>

            <p>
                <li>Competitive salary</li>
                <li>A modern office in Sandton city within easy reach of the trains, trams, and
                    buses.</li>
                <li>Summer and Winter Parties, regular team integration and fun activities.</li>
                <li>Online and in-person training catalogue and career development
                    opportunities</li>
            </p>

            <p>We encourage people from all backgrounds (including individuals with disabilities) who seek
                the opportunity to help re-define the future of mobility. If you feel certain that you are the
                perfect candidate for this position, then what are you waiting for? Apply and join our
                innovative team.</p>

            <Link to={to}><button className="btn primaryBtn">Apply Now</button></Link>
        </div>
    )
}